<template>
	<div class="label-list">
		<el-form :inline="true" class="form-content">

			<el-form-item>

				<el-input placeholder="请输入关键字查询" v-model="search" class="input-with-select" style="width: 400px">
					<el-select style="width: 120px;" v-model="select" slot="prepend" placeholder="请选择">
						<el-option :value="1" label="测评记录id"></el-option>
						<el-option :value="2" label="姓名"></el-option>
						<el-option :value="3" label="身份证号"></el-option>
					</el-select>
					<el-button slot="append" @click="getList" icon="el-icon-search"></el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="测评时间">
				<el-date-picker v-model="dateRangeTime" value-format="yyyy-MM-dd HH:mm:ss" type="daterange"
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="终端设备">
				
				<el-cascader
				@change="getList"
				:options="typeList"
					v-model="deviceId"
					:props="props"
				></el-cascader>
			</el-form-item>
			<el-form-item>
				<el-button @click="getList()" type="primary">搜索</el-button>
				<el-button @click="reset()" type="reset">重置</el-button>
			</el-form-item>
		</el-form>
		<!-- <div class="search-list">

			<el-button type="danger" @click="deleteHandle" :disabled="dataListSelections.length ? false : true">删除
			</el-button>
		</div> -->
		<div class="table">
			<el-table :data="dataList" style="width: 100%" v-loading="dataListLoading"
				@selection-change="selectionChangeHandle">
				<el-table-column type="selection" width="50"></el-table-column>
				<el-table-column min-width="150" prop="taskNum" label="测评记录id">
					<template slot-scope="scope">
						
						<el-button  type="text"
							@click="gotoDetail(scope.row)">{{scope.row.taskNum}}</el-button>
					</template>

				</el-table-column>


				<el-table-column min-width="120"  label="测评人姓名">
<template slot-scope="scope">
					<div style="padding: 11px;">{{scope.row.patientName}}</div>
					</template>
				</el-table-column>
				<el-table-column min-width="130" prop="patientCard" label="测评人身份证号"></el-table-column>
				<el-table-column min-width="160" prop="startTime" label="测评时间"></el-table-column>
				<el-table-column min-width="160" prop="packageName" label="套餐名称">
				
				</el-table-column>
				<!-- <el-table-column prop="createName" label="终端设备">

					<template slot-scope="scope">

					</template>
				</el-table-column> -->
				<el-table-column width="90" label="完成状态">
					<template slot-scope="scope">


						<div v-if="scope.row.startTime&&scope.row.endTime&&scope.row.isSameDay" class="jcwc">已完成</div>
						<div v-else-if="scope.row.startTime&&!scope.row.endTime&&scope.row.today" class="jcwc2">进行中
						</div>
						<div v-else class="jcwc3">已终止</div>
					</template>
				</el-table-column>
				<el-table-column width="220"  label="测评得分">
					<template slot-scope="scope">
					
					<span v-for="item in scope.row.totalScore" style="padding: 5px;">{{item.name}}:{{item.score}};</span>
					</template>
						
				</el-table-column>
				<el-table-column min-width="90" prop="judgeUserName" label="评判人"></el-table-column>
				<el-table-column  min-width="120"label="结果发布状态">
					<template slot-scope="scope">


						<div v-if="scope.row.publishStatus&&scope.row.publishStatus==2">未发布</div>
						<div v-else-if="scope.row.publishStatus&&scope.row.publishStatus==1">已发布</div>
						<div v-else>--</div>
					</template>
				</el-table-column>


				<el-table-column  width="60" fixed="right" label="操作" header-align="right" align="right">
					<template slot-scope="scope">
						<el-button v-if="scope.row.startTime&&scope.row.endTime&&scope.row.isSameDay" type="text"
							@click="showScore(scope.row)">评分</el-button>
						<span v-else>--</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
				:page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
				layout="total, sizes, prev, pager, next, jumper"></el-pagination>
		</div>
		<el-dialog title="评分" :close-on-click-modal="false" :visible.sync="dialogVisible" width="650px">
		
			
			<template v-for="(item,index) in diseaseType">
			<div  class="scoreContent">{{item.name}} </div>
			<div style="display:flex;align-items: center;">
				<div style="margin-right: 20px;">评分</div>
				<el-input  @input="changeInput($event, index)" style="width: 256px;" v-model="item.score"
					placeholder="请输入"></el-input>
			</div>
			
			</template>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dataFormSubmit(1)">保存</el-button>
			<!-- 	<el-button @click="dataFormSubmit(2)">暂存</el-button> -->
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import Qs from "qs";
	import moment from "moment";
	export default {
		name: "label",
		data() {
			return {
				search: "",
				select: 1,
				taskId: '',
				diseaseType: '',
				deviceId: '',
				status: "",
				dialogVisible: false,
				dataList: [],
				dataListLoading: true, //列表loading
				dataListSelections: [], //选中的列
				pageNo: 1,
				pageSize: 10,
				typeList: [
				
				],
				typeList2: [
				
				],
				totalPage: 0, //总页数
				dateRangeTime: [],
				teamsManage: [],
				 
				visible: false,
				form: {

				},
				dataForm: {
					totalScore: '',
					scoreAnalysis: '',
					totalResult: '',
					scoreAdvice: '',
				},
				
				props: {
				    checkStrictly: false, //是否可以选择树干节点作为选项
				    lazy: true, // 是否动态加载子节点
				    // lazyLoad加载动态数据的方法(仅在 lazy 为 true 时有效)
				    lazyLoad: this.loadTreeNode,
				},
				formList: '',
				dataRules: {
					title: [{
						required: true,
						message: "请输入任务主题",
						trigger: "blur"
					}],
				},
				edit: false,
				flag: true, //防止重复点击
				visible2: false,
				dataList2: [],
				dataListLoading2: true, //列表loading
				dataListSelections2: [], //选中的列
				pageNo2: 1,
				pageSize2: 10,
				totalPage2: 0, //总页数
			};
		},
		async mounted() {
				await this.getTypeList2();
	await this.getTypeList();

			await this.getList();
		},
		methods: {
			changeInput(e, index) {
			      this.diseaseType[index].score= e
			      this.$forceUpdate()
			    },
			async showScore(itemObj) {
				this.dataForm.totalScore = itemObj.totalScore
				this.taskId = itemObj.id
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/evaluationPackage/info"),
					method: "post",
					data: {
						stringParam1: itemObj.packageId
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
					this.form = res.data;
					this.diseaseType = JSON.parse(	this.form.diseaseType)
					for (let item of this.diseaseType) {
						
						item.score = ''
					}
					if(itemObj.totalScore&&itemObj.totalScore.length>0){
						let totalScore = JSON.parse(itemObj.totalScore)
						for (let index in  totalScore) {
							this.diseaseType[index].score = totalScore[index].score
							
						}
					}
					console.log('evaluationPackage/info')
console.log(this.diseaseType)
					// if (res.data.packageRule && res.data.packageRule.length > 0) {
					// 	this.formList = JSON.parse(res.data.packageRule)
					// 	console.log(this.formList)
					// 	if (itemObj.totalResult && itemObj.totalResult.length > 0) {
					// 		this.dataForm.totalResult = itemObj.totalResult
					// 		this.dataForm.scoreAnalysis = itemObj.scoreAnalysis
					// 		this.dataForm.scoreAdvice = itemObj.scoreAdvice
					// 	} else {
					// 		for (let item of this.formList) {
					// 			if (this.dataForm.totalScore >= item.score1 && this.dataForm.totalScore <= item
					// 				.score2) {

					// 				this.dataForm.totalResult = item.cpjg
					// 				this.dataForm.scoreAnalysis = item.jgfx
					// 				this.dataForm.scoreAdvice = item.tzjy
					// 			}
					// 		}
					// 	}

					// }

					this.dialogVisible = true


				}
				this.dialogVisible = true
			},
			resetScore(val) {

				if (parseInt(this.dataForm.totalScore) < parseInt(this.formList[0].score1) || parseInt(this.dataForm
						.totalScore) > parseInt(this.formList[this.formList.length - 1].score2)) {
					this.$message.error('请正确填写评分')
					this.dataForm.totalScore = ''
					return
				}
				for (let item of this.formList) {
					if (parseInt(this.dataForm.totalScore) >= parseInt(item.score1) && parseInt(this.dataForm
						.totalScore) <= parseInt(item.score2)) {

						this.dataForm.totalResult = item.cpjg
						this.dataForm.scoreAnalysis = item.jgfx
						this.dataForm.scoreAdvice = item.tzjy
					}
				}
			},
			//加载树节点  首次加载页面时就会执行一次，之后每选中一个节点也会调用，来渲染下一层
		async	loadTreeNode(node, resolve) {
				console.log(node)
			 
					

			
			         if(node.level!=0){
			          
			          
			          
			          let params = {
			          
			          
			          	stringParam3: node.value,
			    
			          
			          	pageSize: 99999,
			          	pageNo: 1,
			          };
			          
			          const {
			          	data: res
			          } = await this.$httpAes({
			          	url: this.$httpAes.adornUrl("/deviceInfo/list"),
			          	method: "post",
			          	data: params,
			          	contentType: 'json',
			          	headers: {
			          		'Content-Type': 'application/json',
			          
			          	},
			          });
			          if (res.status) {
						  res.data.forEach(item=>{
							  item.label = item.deviceName
							  item.value = item.id
							  item.leaf= true
						  })
						resolve(res.data)
						
			          
			          }
			   }
			 
			},
			async getList() {
				let stringParam1 = ''
				let stringParam4 = ''
				let stringParam5 = ''
				let stringParam6 = ''
				let stringParam7 = ''

				if (this.select == 1) {
					stringParam5 = this.search
				}
				if (this.select == 2) {
					stringParam4 = this.search
				}
				if (this.select == 3) {
					stringParam1 = this.search
				}

				if (this.dateRangeTime.length > 0) {
					stringParam6 = this.dateRangeTime[0]
					stringParam7 = this.dateRangeTime[1]
				}
				let params = {

					stringParam1: stringParam1,
					stringParam5: stringParam5,
					stringParam4: stringParam4,
					stringParam8: this.deviceId,
					stringParam6: stringParam6,
					stringParam7: stringParam7,

					pageSize: this.pageSize,
					pageNo: this.pageNo,
				};

				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/diagnosisTask/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
					this.dataList = res.data;
					
					this.totalPage = res.totalCount;
					console.log('/diagnosisTask/list')
						console.log(this.dataList)
					this.dataList.forEach(item => {
						if(item.totalScore){
						
							item.totalScore = JSON.parse(item.totalScore)
							for (let item0 of item.totalScore ) {
								for (let item2 of this.typeList2 ) {
									if(item0.value == item2.value){
										item0.name = item2.name
									}
								}
							}
						}
						item.startTime = moment(new Date(item.startTime)).format('YYYY-MM-DD HH:mm:ss')
						if (item.endTime) {
							item.endTime = moment(new Date(item.endTime)).format('YYYY-MM-DD HH:mm:ss')
						}

						if (item.startTime && this.isSameDay(new Date(), new Date(item.startTime))) {
							item.today = true
						} else {
							item.today = false
						}
						if (item.startTime && item.endTime && this.isSameDay(new Date(item.startTime),
								new Date(item.endTime))) {
							item.isSameDay = true
						}
					})
				}
				this.dataListLoading = false;
			},
			isSameDay(date1, date2) {
				return (
					date1.getFullYear() === date2.getFullYear() && // 年份相等
					date1.getMonth() === date2.getMonth() && // 月份相等
					date1.getDate() === date2.getDate() // 日期相等
				);
			},
			gotoInfo(item) {
				this.$router.push({
					name: "modelInfo",
					query: {
						id: item.id
					},
				});

			},
			add(item) {
				if (item && item.id) {
					// this.form = JSON.parse(JSON.stringify(item));
					this.$router.push({
						name: "addModel",
						query: {
							id: item.id
						},
					});
				} else {
					this.$router.push({
						name: "addModel",
					});

				}

			},
			cancel() {
				this.$refs["form"].resetFields();
				this.visible = false;
				this.form = {
					title: "",
				};
			},
			gotoDetail(item) {
				sessionStorage.evaluationRecDetail = JSON.stringify(item)
			
				 let routeData = this.$router.resolve(({
				    name: 'evaluationRecordDetail', // path 要跳转的路由地址
				    // query 要传递的参数
				    query: { 
				      id: item.id
				     }
				    }))
				    window.open(routeData.href,'_blank')
			
			},
			async dataFormSubmit(status) {
				// if (this.dataForm.totalScore == '' || this.dataForm.totalResult == '' || this.dataForm.scoreAnalysis ==
				// 	'' || this.dataForm.scoreAdvice == '') {
				// 	this.$message.error('请填写完所有评分项')
				// 	return
				// }
				for (let item of this.diseaseType) {
					if(item.score==''){
						this.$message.error('请填写完所有评分项')
						return
					}
				}
				let obj = {
					id: this.taskId,
					totalScore: JSON.stringify(this.diseaseType),
					// totalResult: this.dataForm.totalResult,
					// scoreAnalysis: this.dataForm.scoreAnalysis,
					// scoreAdvice: this.dataForm.scoreAdvice,
					publishStatus: status,
					judgeUserId: JSON.parse(sessionStorage.userInfo).id
				}
				if (status == 1) {
					obj.publishTime = moment(new Date()).format(
						"YYYY-MM-DD HH:mm:ss"
					)
					obj.resultTime = moment(new Date()).format(
						"YYYY-MM-DD HH:mm:ss"
					)
				}
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl(
						"/diagnosisTask/update"
					),
					method: "post",
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
					data: obj,
				});
				console.log(res);
				if (res.status) {
					this.$message({
						message: res.msg,
						type: "success",
						duration: 1500,
						onClose: () => {
							this.dialogVisible = false
							this.getList();
						},
					});


				}
			},

			async getTypeList() {
				let params = {
					pageSize: 9999,
					pageNo: 1,
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("deviceType/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',
			
					},
				});
				console.log(res, '设备型号')
				for (let item of res.data) {
					item.label = item.typeName
					item.value = item.id
					item.leaf = false
				}
				this.typeList = res.data;
			
			},
async getTypeList2() {
				let params = {
					stringParam1: "可评估症状",
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/dict/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				 console.log(res,'可评估症状')
				this.typeList2 = res.data;
			},
			async deleteHandle() {
				let that = this;
				let ids = '';

				ids = that.dataListSelections.map((item) => {
					return item.id;
				});
				ids = ids.join(",");

				that
					.$confirm(`确定进行删除操作?`, "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {

						that
							.$httpAes({
								url: this.$httpAes.adornUrl("/evaluationPackage/delete"),
								method: "post",
								data: {
									stringParam1: ids
								},
								contentType: 'json',
								headers: {
									'Content-Type': 'application/json',

								},
							})
							.then(({
								data
							}) => {
								if (data.status) {
									that.$message({
										message: data.msg,
										type: "success",
										duration: 1500,
										onClose: () => {
											that.getList();
										},
									});
								} else {
									that.$message.error(data.msg);
								}
							});



					})
					.catch(() => {});
			},
			reset() {

				this.dateRangeTime = [];
				this.deviceId=''
				this.search = ''
				this.getList();
			},
			// 每页数
			sizeChangeHandle(val) {
				this.pageSize = val;
				this.pageNo = 1;
				this.getList();
			},
			// 当前页
			currentChangeHandle(val) {
				this.pageNo = val;
				this.getList();
			},
			// 多选
			selectionChangeHandle(val) {
				this.dataListSelections = val;
			},
			// 显示下载列表
			downloadData(row) {
				this.visible2 = true;
				this.getDataList(row.id);
			},
			// 下载内容
			async getDataList(id) {
				let params = {
					pageSize: this.pageSize2,
					pageNo: this.pageNo2,
					retrievalId: id,
				};
				const {
					data: res
				} = await this.$http({
					url: this.$http.adornUrl("/mbglRetrieveRecords/list"),
					method: "get",
					params: params,
				});
				if (res.status) {
					this.dataList2 = res.data;
					this.totalPage2 = res.totalCount;
				}
				this.dataListLoading2 = false;
			},
			// 每页数
			sizeChangeHandle2(val) {
				this.pageSize2 = val;
				this.pageNo2 = 1;
				this.getDataList();
			},
			// 当前页
			currentChangeHandle2(val) {
				this.pageNo2 = val;
				this.getDataList();
			},
			// 添加检索
			addQuestionSearch(row) {
				let params = {
					retrievalId: row.id
				};
				this.$http({
					url: this.$http.adornUrl("/mbglRetrieveRecords/add"),
					method: "post",
					data: Qs.stringify(params),
				}).then(({
					data
				}) => {
					if (data.status) {
						this.updateStatus(row.id);
					}
				});
			},
			updateStatus(id) {
				let params = {
					ids: id,
					status: 3
				};
				this.$http({
					url: this.$http.adornUrl("/mbglLabelIndex/updateStatusByIds"),
					method: "post",
					data: Qs.stringify(params),
				}).then(({
					data
				}) => {
					if (data.status) {
						this.$message({
							message: "重新检索成功",
							type: "success",
							duration: 1500,
							onClose: () => {
								this.$router.push({
									name: "caseSearch",
								});
							},
						});
					}
				});
			},
			// 下载
			downloadUrl(row) {
				var that = this;
				let link = document.createElement("a");
				link.href = row.url;
				link.click();
			},
		},
	};
</script>

<style lang="scss" >
	.el-table th.el-table__cell > .cell{
		padding-left:10px;
	}
	.el-select .el-input {
		width: 130px;
	}

	.top {
		display: flex;

		.left {
			width: 50%;
		}

		.right {
			flex: 1;
		}
	}

	.scoreContent {
		font-size: 14px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 22px;
		width: 100%;
		height: 54px;
		background: #F8F3FF;
		box-sizing: border-box;
		padding: 16px;
		margin-bottom: 24px;
	}
</style>